import ApiService from "@/common/api.service";
import handleReject from "@/common/handleReject";
import Session from "@/common/Session";

import {
  CREATE_ARTICLE_EPISODE,
  UPDATE_ARTICLE_EPISODE,
  GET_ARTICLE_EPISODES_LIST,
  FIND_ARTICLE_EPISODE,
  SWITCH_ARTICLE_EPISODE_STATE,
  SET_ARTICLE_EPISODE,
  SET_ARTICLE_EPISODES,
  SET_ARTICLE_EPISODE_ERROR,
} from "../types";

const state = {
  articleEpisode: {},
  articleEpisodes: [],
  articleEpisodesMeta: {},
  articleEpisodeError: null
};

const mutations = {
  [SET_ARTICLE_EPISODE](state, payload){
    state.articleEpisode = payload;
    Session.add(`article_episode_${payload.id}`, payload);
  },
  [SET_ARTICLE_EPISODES](state, payload){
    let { data: episode, meta } = payload;
    state.articleEpisodes = episode;
    state.articleEpisodesMeta = meta;
  },
  [SET_ARTICLE_EPISODE_ERROR](state, payload){
    state.articleEpisodeError = payload;
  },
};

const actions = {
  [CREATE_ARTICLE_EPISODE](context, { aid, formData }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/articles/${aid}/episodes`, formData)
      .then(response=>{
        context.commit(SET_ARTICLE_EPISODE, response.data);
        context.commit(SET_ARTICLE_EPISODE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_EPISODE_ERROR);
        reject(response);
      })
    })
  },
  [UPDATE_ARTICLE_EPISODE](context, { aid, formData }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.update(`/manager/articles/${aid}/episodes`, formData.id, formData)
      .then(response=>{
        context.commit(SET_ARTICLE_EPISODE, response.data);
        context.commit(SET_ARTICLE_EPISODE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_EPISODE_ERROR);
        reject(response);
      })
    })
  },
  [GET_ARTICLE_EPISODES_LIST](context, { aid, page = 1 }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`/manager/articles/${aid}/episodes`, `?page=${page}`)
      .then(response=>{
        context.commit(SET_ARTICLE_EPISODES, response.data);
        context.commit(SET_ARTICLE_EPISODE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_EPISODE_ERROR);
        reject(response);
      })
    })
  },
  [FIND_ARTICLE_EPISODE](context, { aid, id }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`/manager/articles/${aid}/episodes`, id)
      .then(response=>{
        context.commit(SET_ARTICLE_EPISODE, response.data);
        context.commit(SET_ARTICLE_EPISODE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_EPISODE_ERROR);
        reject(response);
      })
    })
  },
  [SWITCH_ARTICLE_EPISODE_STATE](context, {aid, id, params}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/articles/${aid}/episodes/${id}/switch`, params)
      .then(response=>{
        context.commit(SET_ARTICLE_EPISODE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_EPISODE_ERROR);
        reject(response);
      })
    })
  }
};

export default {
  state,
  mutations,
  actions
};
