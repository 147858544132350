import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import adverts from "./modules/adverts";
import user from "./modules/user";
import setting from "./modules/setting";
import category from "./modules/category";
import collection from "./modules/collection";
import membership from "./modules/membership";
import wallet from "./modules/wallet";
import walletCashRequest from "./modules/walletCashRequest";
import customer from "./modules/weChat/customer";
import author from "./modules/author";
import navigation from "./modules/navigation";
import article from "./modules/article";
import articleEpisode from "./modules/articleEpisode";
import order from "./modules/order";
import download from "./modules/articleDownload.module";

Vue.use(Vuex);
import getters from "./getters";
import { SET_ERROR } from "./types";

export default new Vuex.Store({
  state: {
    errors: null
  },
  mutations: {
    [SET_ERROR](state, payload){
      state.errors = payload;
    }
  },
  actions: {},
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    adverts,
    user,
    setting,
    category,
    collection,
    membership,
    wallet,
    walletCashRequest,
    customer,
    author,
    navigation,
    article,
    articleEpisode,
    order,
    download
  },
  getters
});
