class Session {
  // 新增一个缓存
  add(key, data) {
    // 设置缓存名称
    let name = this.makeCacheKey(key);
    // 缓存数据
    let _data = {
      data
    };
    // 对数据进行缓存
    try {
      window.sessionStorage.setItem(name, JSON.stringify(_data));
      // 返回数据
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  // 获取数据
  get(key) {
    // 如果数据不存在则返回null
    if (!this.has(key)) {
      return null;
    }
    // 返回数据
    let data = this._get(key);
    return data ? data.data : null;
  }

  // 更新已有的数据
  update(key, value) {
    this.add(key, value);
    return value;
  }

  _get(key) {
    // 设置缓存名称
    let name = this.makeCacheKey(key);
    // 获取缓存数据
    try {
      let data = window.sessionStorage.getItem(name);
      if (data) {
        return JSON.parse(data);
      }
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  // 是否存在
  has(key) {
    try {
      // 根据名称获取相应的数据
      let data = this._get(key);
      // 缓存数据不存在或者缓存已过期
      if (!data) {
        console.log("[缓存是否存在: 不存在], ", key);
        return false;
      }
      // 数据存在并且还在有效期内
      return true;
    } catch (e) {
      console.log("可能不存在");
      return false;
    }
  }

  // 生成一个缓存key
  makeCacheKey(key) {
    return "session_data_" + key;
  }

  // 删除指定的缓存
  destroy(key) {
    // 根据key获取缓存key
    let name = this.makeCacheKey(key);
    try {
      window.sessionStorage.removeItem(name);
    } catch (e) {
      return null;
    }
  }
}

export default new Session();
