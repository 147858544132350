import ApiService from "@/common/api.service";
import handleReject from "../../../common/handleReject";

export const SET_CUSTOMER_ERROR = 'setCustomerError';

import {
  GET_CUSTOMER_MESSAGES,
  SET_CUSTOMER_MESSAGES,
  SET_CUSTOMER_MESSAGES_META,
  SET_CUSTOMER_MESSAGES_PAGE
} from "../../types"

const state = {
  customerMessages: [],
  customerMessagesMeta: {},
  customerMessagesPage: 1,
  customerError: null,
};

const mutations = {
  [SET_CUSTOMER_MESSAGES](state, payload){
    state.customerMessages = payload;
  },
  [SET_CUSTOMER_MESSAGES_META](state, payload){
    state.customerMessagesMeta = payload;
  },
  [SET_CUSTOMER_MESSAGES_PAGE](state, page){
    state.customerMessagesPage = page;
  },
  [SET_CUSTOMER_ERROR](state, payload){
    state.customerError = payload;
  }
};

const actions = {
  [GET_CUSTOMER_MESSAGES](context, page = 1){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/we-chat/customer/message', `?page=${page}`)
      .then(response=>{
        let { data, meta } = response.data;
        context.commit(SET_CUSTOMER_MESSAGES, data);
        context.commit(SET_CUSTOMER_MESSAGES_META, meta);
        context.commit(SET_CUSTOMER_MESSAGES_PAGE, page);
        context.commit(SET_CUSTOMER_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_CUSTOMER_ERROR);
        reject(response);
      })
    })
  }
};

export default {
  state,
  mutations,
  actions
}
