import ApiService from "@/common/api.service";
import handleReject from "@/common/handleReject";

import {
  CREATE_NAVIGATION,
  UPDATE_NAVIGATION,
  GET_NAVIGATIONS_LIST,
  FIND_NAVIGATION,
  SWITCH_NAVIGATION_STATE,
  SET_NAVIGATION,
  SET_NAVIGATIONS,
  SET_NAVIGATION_ERROR,
} from "../types";

const state = {
  navigation: {},
  navigations: [],
  navigationsMeta: {},
  navigationError: null
};

const mutations = {
  [SET_NAVIGATION](state, category){
    state.navigation = category;
  },
  [SET_NAVIGATIONS](state, payload){
    let { data: navigations, meta } = payload;
    state.navigations = navigations;
    state.navigationsMeta = meta;
  },
  [SET_NAVIGATION_ERROR](state, payload){
    state.navigationError = payload;
  },
};

const actions = {
  [CREATE_NAVIGATION](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/manager/navigations', formData)
      .then(response=>{
        context.commit(SET_NAVIGATION, response.data);
        context.commit(SET_NAVIGATION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_NAVIGATION_ERROR);
        reject(response);
      })
    })
  },
  [UPDATE_NAVIGATION](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.update('/manager/navigations', formData.id, formData)
      .then(response=>{
        context.commit(SET_NAVIGATION, response.data);
        context.commit(SET_NAVIGATION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_NAVIGATION_ERROR);
        reject(response);
      })
    })
  },
  [GET_NAVIGATIONS_LIST](context, options = {page: 1, title: ''}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/navigations', `?page=${options.page}&title=${options.title}`)
      .then(response=>{
        context.commit(SET_NAVIGATIONS, response.data);
        context.commit(SET_NAVIGATION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_NAVIGATION_ERROR);
        reject(response);
      })
    })
  },
  [FIND_NAVIGATION](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/navigations', id)
      .then(response=>{
        context.commit(SET_NAVIGATION, response.data);
        context.commit(SET_NAVIGATION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_NAVIGATION_ERROR);
        reject(response);
      })
    })
  },
  [SWITCH_NAVIGATION_STATE](context, {id, params}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/navigations/${id}/switch`, params)
      .then(response=>{
        context.commit(SET_NAVIGATION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_NAVIGATION_ERROR);
        reject(response);
      })
    })
  },
};

export default {
  state,
  mutations,
  actions
};
