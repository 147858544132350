import ApiService from "@/common/api.service";
import handleReject from "@/common/handleReject";

import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  GET_CATEGORIES_LIST,
  FIND_CATEGORY,
  SWITCH_CATEGORY_STATE,
  SET_CATEGORY,
  SET_CATEGORIES,
  SET_CATEGORY_ERROR, GET_CATEGORIES_FOR_ALL, SET_CATEGORIES_FOR_ALL
} from "../types";

const state = {
  category: {},
  categories: [],
  categoriesMeta: {},
  categoryError: null,
  categoriesForAll: []
};

const mutations = {
  [SET_CATEGORY](state, category){
    state.category = category;
  },
  [SET_CATEGORIES](state, payload){
    let { data: categories, meta } = payload;
    state.categories = categories;
    state.categoriesMeta = meta;
  },
  [SET_CATEGORY_ERROR](state, payload){
    state.categoryError = payload;
  },
  [SET_CATEGORIES_FOR_ALL](state, payload){
    state.categoriesForAll = payload;
  },
};

const actions = {
  [CREATE_CATEGORY](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/manager/categories', formData)
      .then(response=>{
        context.commit(SET_CATEGORY, response.data);
        context.commit(SET_CATEGORY_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_CATEGORY_ERROR);
        reject(response);
      })
    })
  },
  [UPDATE_CATEGORY](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.update('/manager/categories', formData.id, formData)
      .then(response=>{
        context.commit(SET_CATEGORY, response.data);
        context.commit(SET_CATEGORY_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_CATEGORY_ERROR);
        reject(response);
      })
    })
  },
  [GET_CATEGORIES_LIST](context, options = {page: 1, title: ''}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/categories', `?page=${options.page}&title=${options.title}`)
      .then(response=>{
        context.commit(SET_CATEGORIES, response.data);
        context.commit(SET_CATEGORY_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_CATEGORY_ERROR);
        reject(response);
      })
    })
  },
  [FIND_CATEGORY](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/categories', id)
      .then(response=>{
        context.commit(SET_CATEGORY, response.data);
        context.commit(SET_CATEGORY_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_CATEGORY_ERROR);
        reject(response);
      })
    })
  },
  [SWITCH_CATEGORY_STATE](context, {id, params}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/categories/${id}/switch`, params)
      .then(response=>{
        context.commit(SET_CATEGORY_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_CATEGORY_ERROR);
        reject(response);
      })
    })
  },
  [GET_CATEGORIES_FOR_ALL](context){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/categories/list-for-all')
      .then(response=>{
        context.commit(SET_CATEGORIES_FOR_ALL, response.data);
        context.commit(SET_CATEGORY_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_CATEGORY_ERROR);
        reject(response);
      })
    })
  },
};

export default {
  state,
  mutations,
  actions
};
