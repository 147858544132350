import ApiService from "@/common/api.service";
import handleReject from "../../common/handleReject";
import { SET_WALLET, SET_WALLET_LIST } from "../types";

export const SET_WALLET_ERROR = 'setWalletError';

const state = {
  wallet: {}, // 钱包数据
  walletList: [], // 钱包列表
  walletMeta: {}, // 钱包列表分页相关数据
  walletError: {}, // 钱包数据发生的错误
};

const mutations = {
  [SET_WALLET](state, wallet){
    state.wallet = wallet;
  },
  [SET_WALLET_LIST](state, payload){
    let { data: filmList, meta } = payload;
    state.walletList = filmList;
    state.walletMeta = meta;
  },
  [SET_WALLET_ERROR](state, errors){
    state.walletError = errors;
  },
}

const actions = {}

export default {
  state,
  mutations,
  actions
}
