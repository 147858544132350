import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import handleReject from "../common/handleReject";
import Cache from "../common/cache";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_ADMIN = "updateAdmin";
export const CHANGE_PASSWORD = "changePassword";
export const REFRESH_TOKEN = "refreshToken";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_ERROR = "setError";
export const SET_MENU_ITEMS = "setMenuItems";
export const SET_ARTICLE_CONTENT_MODELS = "setArticleContentModels";

const handleAuth = (context, data)=>{
  context.commit(SET_AUTH, data);
  Cache.destroy('menuItems');
  Cache.destroy('articleContentModels');
  handleCommit(context, data, 'menuItems', SET_MENU_ITEMS);
  handleCommit(context, data, 'article_setting', SET_ARTICLE_CONTENT_MODELS, [], data=> data.hasOwnProperty( 'article_setting' ) ? data.article_setting.content_models : []);
}
const handleCommit = (context, data, key, types, defaults = [], value = null)=>{
  context.commit(types, data.hasOwnProperty(key) ? typeof value === 'function' ? value(data) : data[key] : defaults);
}

const state = {
  errors: null,
  admin: {},
  isAuthenticated: !!JwtService.getToken(),
  menuItems: [],
  articleContentModels:[]
};

const getters = {
  currentUser(state) {
    return state.admin;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  menuItems(state){
    if ( !Cache.hasExpired('menuItems') )
      return Cache.get('menuItems');
    return state.menuItems;
  },
  articleContentModels(state){
    if ( !Cache.hasExpired('articleContentModels') )
      return Cache.get('articleContentModels');
    return state.articleContentModels;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/manager/authorizations/login", credentials)
        .then(response => {
          handleAuth(context, response.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch((response) => {
          handleReject(context, response, SET_ERROR);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {});
  },
  [VERIFY_AUTH](context, router = null) {
    if (JwtService.getToken(true)) {
      ApiService.setHeader();
      ApiService.get("/manager/authorizations/verify")
      .then(response => {
        handleAuth(context, response.data);
        context.commit(SET_ERROR, null);
      })
      .catch(response => {
        handleReject(context, response, SET_ERROR);
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_ADMIN](context, payload) {
    const { phone, nickname, avatar } = payload;
    const user = { phone, nickname, avatar };
    ApiService.setHeader();
    return ApiService.put("/manager/administrator", user)
    .then(response => {
      let { data } = response;
      context.commit(SET_AUTH, data);
      return data;
    })
    .catch(response => {
      handleReject(context, response, SET_ERROR);
    });
  },
  [CHANGE_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post( "/manager/administrator/change-password", payload )
      .then( response => {
        let { data } = response;
        context.commit( SET_ERROR, null );
        resolve(data);
      })
      .catch(response => {
        handleReject(context, response, SET_ERROR);
        reject(response);
      });
    })
  },
  [REFRESH_TOKEN](context){
    return new Promise((resolve, reject) => {
      ApiService.setHeader(true);
      ApiService.post("/manager/authorizations/refresh")
      .then(response => {
        handleAuth(context, response.data);
        context.commit(SET_ERROR, null);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        handleReject(context, response, SET_ERROR);
      });
    })
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, payload) {
    state.isAuthenticated = true;
    state.admin = payload.admin;
    state.errors = {};
    JwtService.saveToken(payload.token.access_token, payload.token.expires_in);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.admin = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_MENU_ITEMS](state, payload){
    state.menuItems = payload;
    Cache.add('menuItems', payload);
  },
  [SET_ARTICLE_CONTENT_MODELS](state, payload){
    state.articleContentModels = payload;
    Cache.add('articleContentModels', payload);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
