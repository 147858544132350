import ApiService from "@/common/api.service";
import handleReject from "../../common/handleReject";

import {
  CREATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP,
  GET_MEMBERSHIP_LIST,
  FIND_MEMBERSHIP,
  SWITCH_MEMBERSHIP_STATE,
  SET_MEMBERSHIP,
  SET_MEMBERSHIP_LIST,
  SET_MEMBERSHIP_ERROR
} from "../types";

const state = {
  membership: {},
  membershipList: [],
  membershipListMeta: {},
  membershipError: null
};

const mutations = {
  [SET_MEMBERSHIP](state, payload){
    state.membership = payload;
  },
  [SET_MEMBERSHIP_LIST](state, payload){
    let { data, meta } = payload;
    state.membershipList = data;
    state.membershipListMeta = meta;
  },
  [SET_MEMBERSHIP_ERROR](state, payload){
    state.membershipError = payload;
  }
};

const actions = {
  [CREATE_MEMBERSHIP](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/manager/membership', formData)
      .then(response=>{
        context.commit(SET_MEMBERSHIP, response.data);
        context.commit(SET_MEMBERSHIP_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_MEMBERSHIP_ERROR);
        reject(response);
      })
    })
  },
  [UPDATE_MEMBERSHIP](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.update('/manager/membership', formData.id, formData)
      .then(response=>{
        context.commit(SET_MEMBERSHIP, response.data);
        context.commit(SET_MEMBERSHIP_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_MEMBERSHIP_ERROR);
        reject(response);
      })
    })
  },
  [GET_MEMBERSHIP_LIST](context, { page = 1, title = '' }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/membership', `?page=${page || 1}&title=${title || ''}`)
      .then(response=>{
        context.commit(SET_MEMBERSHIP_LIST, response.data);
        context.commit(SET_MEMBERSHIP_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_MEMBERSHIP_ERROR);
        reject(response);
      })
    })
  },
  [FIND_MEMBERSHIP](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/membership', id)
      .then(response=>{
        context.commit(SET_MEMBERSHIP, response.data);
        context.commit(SET_MEMBERSHIP_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_MEMBERSHIP_ERROR);
        reject(response);
      })
    })
  },
  [SWITCH_MEMBERSHIP_STATE](context, {id, params}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/membership/${id}/switch`, params)
      .then(response=>{
        context.commit(SET_MEMBERSHIP_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_MEMBERSHIP_ERROR);
        reject(response);
      })
    })
  }
};

export default {
  state,
  mutations,
  actions
};
