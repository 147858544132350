import ApiService from "@/common/api.service";
import handleReject from "@/common/handleReject";

import {
  CREATE_COLLECTION,
  UPDATE_COLLECTION,
  GET_COLLECTIONS_LIST,
  FIND_COLLECTION,
  SWITCH_COLLECTION_STATE,
  SET_COLLECTION,
  SET_COLLECTIONS,
  SET_COLLECTION_ERROR, GET_COLLECTIONS_FOR_ALL, SET_COLLECTIONS_FOR_ALL
} from "../types";

const state = {
  collection: {},
  collections: [],
  collectionsMeta: {},
  collectionError: null,
  collectionsForAll: []
};

const mutations = {
  [SET_COLLECTION](state, collection){
    state.collection = collection;
  },
  [SET_COLLECTIONS](state, payload){
    let { data: collections, meta } = payload;
    state.collections = collections;
    state.collectionsMeta = meta;
  },
  [SET_COLLECTION_ERROR](state, payload){
    state.collectionError = payload;
  },
  [SET_COLLECTIONS_FOR_ALL](state, payload){
    state.collectionsForAll = payload;
  },
};

const actions = {
  [CREATE_COLLECTION](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/manager/collection', formData)
      .then(response=>{
        context.commit(SET_COLLECTION, response.data);
        context.commit(SET_COLLECTION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_COLLECTION_ERROR);
        reject(response);
      })
    })
  },
  [UPDATE_COLLECTION](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.update('/manager/collection', formData.id, formData)
      .then(response=>{
        context.commit(SET_COLLECTION, response.data);
        context.commit(SET_COLLECTION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_COLLECTION_ERROR);
        reject(response);
      })
    })
  },
  [GET_COLLECTIONS_LIST](context, options = {page: 1, title: ''}){

    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/collection', `?page=${options.page}&title=${options.title}`)
      .then(response=>{
        context.commit(SET_COLLECTIONS, response.data);
        context.commit(SET_COLLECTION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_COLLECTION_ERROR);
        reject(response);
      })
    })
  },
  [FIND_COLLECTION](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/collection', id)
      .then(response=>{
        context.commit(SET_COLLECTION, response.data);
        context.commit(SET_COLLECTION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_COLLECTION_ERROR);
        reject(response);
      })
    })
  },
  [SWITCH_COLLECTION_STATE](context, {id, params}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/collection/${id}/switch`, params)
      .then(response=>{
        context.commit(SET_COLLECTION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_COLLECTION_ERROR);
        reject(response);
      })
    })
  },
  [GET_COLLECTIONS_FOR_ALL](context){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/collection/list-for-all')
      .then(response=>{
        context.commit(SET_COLLECTIONS_FOR_ALL, response.data);
        context.commit(SET_COLLECTION_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_COLLECTION_ERROR);
        reject(response);
      })
    })
  },
};

export default {
  state,
  mutations,
  actions
};
