import ApiService from "@/common/api.service";
import handleReject from "../../common/handleReject";

export const SET_USER_ERROR = 'setUserError';

import {
  FIND_USER,
  FIND_ENTRUST_USER,
  GET_USER_LIST,
  GET_ENTRUST_USER_LIST,
  GET_ENTRUST_STATICS,
  SWITCH_USER_STATE,
  SET_USER,
  SET_ENTRUST_USER,
  SET_USER_LIST,
  SET_ENTRUST_USER_LIST,
  SET_USER_META,
  SET_ENTRUST_USER_META,
  SET_ENTRUST_STATICS, SET_USER_WALLET_LOGS, GET_USER_WALLET_LOGS
} from "../types";

const state = {
  user: {}, // 当前的用户的数据
  users: [], // 用户列表
  usersMeta: {}, // 用户列表分页相关数据
  userError: {}, // 用户数据发生的错误
  entrustUser: {}, // 当前的用户的数据
  entrustUsers: [], // 用户列表
  entrustUsersMeta: {}, // 用户列表分页相关数据
  entrustStatics: {},
  userWalletLogs: [],
  userWalletLogsMeta: {},
};

const mutations = {
  [SET_USER](state, user){
    state.user = user;
  },
  [SET_ENTRUST_USER](state, user){
    state.entrustUser = user;
  },
  [SET_ENTRUST_STATICS](state, statics){
    state.entrustStatics = statics;
  },
  [SET_USER_LIST](state, list){
    state.users = list;
  },
  [SET_ENTRUST_USER_LIST](state, list){
    state.entrustUsers = list;
  },
  [SET_USER_META](state, meta){
    state.usersMeta = meta;
  },
  [SET_ENTRUST_USER_META](state, meta){
    state.entrustUsersMeta = meta;
  },
  [SET_USER_ERROR](state, payload){
    state.userError = payload;
  },
  [SET_USER_WALLET_LOGS] (state, payload){
    state.userWalletLogs = payload.data;
    state.userWalletLogsMeta = payload.meta;
  }
};

const actions = {
  [GET_USER_LIST](context, options = {page: 1, name: ''}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/user', `?page=${options.page}&name=${options.name}`)
      .then(response=>{
        context.commit(SET_USER_LIST, response.data.data);
        context.commit(SET_USER_META, response.data.meta);
        context.commit(SET_USER_ERROR, null);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        handleReject(context, response, SET_USER_ERROR);
        // context.commit(SET_USER_ERROR, response.data.errors || response.data.message || response.data);
      });
    })
  },
  [GET_ENTRUST_USER_LIST](context, options = {page: 1}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/manager/user/entrust/user', options)
      .then(response=>{
        context.commit(SET_ENTRUST_USER_LIST, response.data.data);
        context.commit(SET_ENTRUST_USER_META, response.data.meta);
        context.commit(SET_USER_ERROR, null);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        handleReject(context, response, SET_USER_ERROR);
        // context.commit(SET_USER_ERROR, response.data.errors || response.data.message || response.data);
      });
    })
  },
  [GET_ENTRUST_STATICS](context, options = {}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/manager/user/entrust/statics', options)
      .then(response=>{
        context.commit(SET_ENTRUST_STATICS, response.data);
        context.commit(SET_USER_ERROR, null);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        handleReject(context, response, SET_USER_ERROR);
        // context.commit(SET_USER_ERROR, response.data.errors || response.data.message || response.data);
      });
    })
  },
  [FIND_USER](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`/manager/user/${id}`)
      .then(response=>{
        context.commit(SET_USER, response.data);
        context.commit(SET_USER_ERROR, null);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        handleReject(context, response, SET_USER_ERROR);
      });
    })
  },
  [SWITCH_USER_STATE](context, {id, params}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/user/${id}/switch`, params)
      .then(response=>{
        context.commit(SET_USER_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_USER_ERROR);
        reject(response);
      })
    })
  },
  [GET_USER_WALLET_LOGS](context,{id, page = 1, params = {}}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`/manager/user/${id}/wallet-logs`, `?page=${page}&start_time=${params.start_time ? params.start_time : ''}&end_time=${params.end_time ? params.end_time : ''}`)
      .then(response=>{
        context.commit(SET_USER_WALLET_LOGS, response.data);
        context.commit(SET_USER_ERROR, null);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        handleReject(context, response, SET_USER_ERROR);
      });
    })
  }
};

export default {
  state,
  mutations,
  actions
}
