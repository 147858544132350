import ApiService from "@/common/api.service";

import {
  FIND_ADVERTS,
  GET_ADVERTS_LIST,
  SET_ADVERTS,
  SET_ADVERTS_LIST,
  SET_ADVERTS_META,
  SET_ADVERTS_ERROR
} from "../types";
import handleReject from "../../common/handleReject";

const state = {
  advert: {}, // 当前的医生数据
  adverts: [], // 医生列表
  advertsMeta: {}, // 医生列表分页相关数据
  advertsError: {}
};

const mutations = {
  [SET_ADVERTS](state, advert){
    state.advert = advert;
  },
  [SET_ADVERTS_LIST](state, list){
    state.adverts = list;
  },
  [SET_ADVERTS_META](state, meta){
    state.advertsMeta = meta;
  },
  [SET_ADVERTS_ERROR](state, payload){
    state.advertsError = payload;
  }
};

const actions = {
  [GET_ADVERTS_LIST](context, page = 1){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/advert', `?page=${page}`)
      .then(response=>{
        context.commit(SET_ADVERTS_LIST, response.data.data);
        context.commit(SET_ADVERTS_META, response.data.meta);
        context.commit(SET_ADVERTS_ERROR, null);
        resolve(response);
      })
      .catch(response => {
        // context.commit(SET_ADVERTS_ERROR, response.data.errors || response.data);
        reject(response);
        handleReject(context, response, SET_ADVERTS_ERROR);
      });
    })
  },
  [FIND_ADVERTS](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`/manager/advert/${id}`)
      .then(response=>{
        context.commit(SET_ADVERTS, response.data);
        context.commit(SET_ADVERTS_ERROR, null);
        resolve(response);
      })
      .catch(( response ) => {
        // context.commit(SET_ADVERTS_ERROR, response.data.errors || response.data);
        reject(response);
        handleReject(context, response, SET_ADVERTS_ERROR);
      });
    })
  }
};

export default {
  state,
  mutations,
  actions
}
