class Cache {
  // 新增一个缓存
  add(key, data, expired_time = 1440 * 30) {
    // 设置缓存名称
    let name = this.makeCacheKey(key);
    // 设置缓存过期时间
    let expired_at = this.makeExpiredTime(expired_time);
    // 缓存数据
    let _data = {
      data,
      expired_at
    };
    // 对数据进行缓存
    try {
      window.localStorage.setItem(name, JSON.stringify(_data));
      // 返回数据
      return _data;
    } catch (e) {
      console.log(e);
    }
  }

  // 获取数据
  get(key, ignoreTTL = false) {
    // 如果数据不存在则返回null
    if (!this.has(key)) {
      return null;
    }
    // 如果过期了返回空
    if (this.hasExpired(key) && !ignoreTTL) return null;
    // 返回数据
    let data = this._get(key);
    return data ? data.data : null;
  }

  // 更新已有的数据
  update(key, value, expired_time = null) {
    if (this.has(key)) {
      // 设置缓存名称
      let name = this.makeCacheKey(key);
      // 获取数据
      let data = this._get(key);
      let _data = {
        data: value
      };
      if (expired_time) {
        // 设置缓存过期时间
        _data.expired_at = this.makeExpiredTime(expired_time);
      } else {
        _data.expired_at = data.expired_at;
      }
      // 对数据进行缓存
      try {
        window.localStorage.setItem(name, JSON.stringify(_data));
        // 返回数据
        return value;
      } catch (e) {}
    } else {
      this.add(key, value);
      return value;
    }
  }

  _get(key) {
    // 设置缓存名称
    let name = this.makeCacheKey(key);
    // 获取缓存数据
    try {
      let data = window.localStorage.getItem(name);
      if (data) {
        return JSON.parse(data);
      }
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  // 是否存在
  has(key) {
    try {
      // 根据名称获取相应的数据
      let data = this._get(key);
      // 缓存数据不存在或者缓存已过期
      if (!data) {
        console.log("[缓存是否存在: 不存在], ", key);
        return false;
      }
      // 数据存在并且还在有效期内
      return true;
    } catch (e) {
      console.log("可能不存在");
      return false;
    }
  }
  // 是否过期
  hasExpired(key) {
    try {
      // 根据名称获取相应的数据
      let data = this._get(key);
      if (!data) {
        // 如果缓存不存在
        console.log("[缓存是否过期: 不存在], ", key);
        return true;
      }
      // 当前时间
      let current = Math.round(new Date().getTime() / 1000);
      // 已过期
      if (current > data.expired_at) {
        console.log("[缓存已过期], ", key);
        return true;
      }
      // 没过期
      return false;
    } catch (e) {
      console.log("发生了意外，可能不存在数据");
      return true;
    }
  }
  // 生成一个缓存key
  makeCacheKey(key) {
    return "cache_data_" + key;
  }

  // 生成缓存过期时间
  makeExpiredTime(minutes = 10) {
    let date = new Date();
    let seconds = 60000 * minutes;
    return Math.round((date.getTime() + seconds) / 1000);
  }

  destroy(key) {
    // 根据key获取缓存key
    let name = this.makeCacheKey(key);
    try {
      window.localStorage.removeItem(name);
    } catch (e) {
      return null;
    }
  }
}

export default new Cache();
