import ApiService from "@/common/api.service";
import handleReject from "@/common/handleReject";
import Session from "@/common/Session";

import {
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  GET_ARTICLES_LIST,
  FIND_ARTICLE,
  SWITCH_ARTICLE_STATE,
  SET_ARTICLE,
  SET_ARTICLES,
  SET_ARTICLE_ERROR,
  GET_ARTICLES_FOR_ALL,
  SET_ARTICLES_FOR_ALL,
  GET_ARTICLES_COMMENTS,
  SET_ARTICLES_COMMENTS,
} from "../types";

const state = {
  article: {},
  articles: [],
  articlesComment: [],
  articlesMeta: {},
  articlesCommentMeta: {},
  articleError: null,
  articlesForAll: [],
};

const mutations = {
  [SET_ARTICLE](state, article){
    state.article = article;
    Session.add(`article_${article.id}`, article);
  },
  [SET_ARTICLES](state, payload){
    let { data: articles, meta } = payload;
    state.articles = articles;
    state.articlesMeta = meta;
  },
  [SET_ARTICLES_COMMENTS](state, payload){
    let { data: comments, meta } = payload;
    state.articlesComment = comments;
    state.articlesCommentMeta = meta;
  },
  [SET_ARTICLE_ERROR](state, payload){
    state.articleError = payload;
  },
  [SET_ARTICLES_FOR_ALL](state, payload){
    state.articlesForAll = payload;
  },
};

const actions = {
  [CREATE_ARTICLE](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/manager/articles', formData)
      .then(response=>{
        context.commit(SET_ARTICLE, response.data);
        context.commit(SET_ARTICLE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_ERROR);
        reject(response);
      })
    })
  },
  [UPDATE_ARTICLE](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.update('/manager/articles', formData.id, formData)
      .then(response=>{
        context.commit(SET_ARTICLE, response.data);
        context.commit(SET_ARTICLE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_ERROR);
        reject(response);
      })
    })
  },
  [GET_ARTICLES_LIST](context, options = {page: 1, title: ''}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/articles', `?page=${options.page}&title=${options.title}`)
      .then(response=>{
        context.commit(SET_ARTICLES, response.data);
        context.commit(SET_ARTICLE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_ERROR);
        reject(response);
      })
    })
  },
  [FIND_ARTICLE](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/articles', id)
      .then(response=>{
        context.commit(SET_ARTICLE, response.data);
        context.commit(SET_ARTICLE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_ERROR);
        reject(response);
      })
    })
  },
  [SWITCH_ARTICLE_STATE](context, {id, params}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/articles/${id}/switch`, params)
      .then(response=>{
        context.commit(SET_ARTICLE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_ERROR);
        reject(response);
      })
    })
  },
  [GET_ARTICLES_FOR_ALL](context){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/articles/list-for-all')
      .then(response=>{
        context.commit(SET_ARTICLES_FOR_ALL, response.data);
        context.commit(SET_ARTICLE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_ERROR);
        reject(response);
      })
    })
  },
  [GET_ARTICLES_COMMENTS](context, {page = 1, filter = 0}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/articles/comments', `?page=${page}&filter=${filter}`)
      .then(response=>{
        context.commit(SET_ARTICLES_COMMENTS, response.data);
        context.commit(SET_ARTICLE_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_ARTICLE_ERROR);
        reject(response);
      })
    })
  },
};

export default {
  state,
  mutations,
  actions
};
