import ApiService from "@/common/api.service";
import handleReject from "@/common/handleReject";

export const SET_DOWNLOAD = 'setDownload';
export const SET_DOWNLOADS = 'setDownloads';
export const SET_DOWNLOAD_ERROR = 'setDownloadError';

export const GET_DOWNLOAD = 'getDownload';
export const GET_DOWNLOADS = 'getDownloads';
export const STORE_DOWNLOAD = 'storeDownload';
export const DELETE_DOWNLOAD = 'deleteDownload';


const state = {
  download: {},
  downloads: [],
  downloadError: null,
};

const mutations = {
  [SET_DOWNLOAD](state, download){
    state.download = download;
  },
  [SET_DOWNLOADS](state, payload){
    state.downloads = payload;
  },
  [SET_DOWNLOAD_ERROR](state, payload){
    state.downloadError = payload;
  },
};

const getters = {
  download: store=> store.download,
  downloads: store=> store.downloads,
  downloadError: store=> store.downloadError,
};

const actions = {
  [GET_DOWNLOAD](context, { aid, id }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`/manager/articles/${aid}/downloads/${id}`)
      .then(response=>{
        context.commit(SET_DOWNLOAD, response.data);
        context.commit(SET_DOWNLOAD_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_DOWNLOAD_ERROR);
        reject(response);
      })
    })
  },
  [GET_DOWNLOADS](context, aid){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`/manager/articles/${aid}/downloads`)
      .then(response=>{
        context.commit(SET_DOWNLOADS, response.data);
        context.commit(SET_DOWNLOAD_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_DOWNLOAD_ERROR);
        reject(response);
      })
    })
  },
  [STORE_DOWNLOAD](context, { aid, formData }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/articles/${aid}/downloads`, formData)
      .then(response=>{
        context.commit(SET_DOWNLOAD, response.data);
        context.commit(SET_DOWNLOAD_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_DOWNLOAD_ERROR);
        reject(response);
      })
    })
  },
  [DELETE_DOWNLOAD](context, { aid, id }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete(`/manager/articles/${aid}/downloads/${id}`)
      .then(response=>{
        context.commit(SET_DOWNLOAD_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        handleReject(context, response, SET_DOWNLOAD_ERROR);
        reject(response);
      })
    })
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
