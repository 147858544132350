import { REFRESH_TOKEN } from "../store/auth.module";
import Cache from "./cache";

import { ID_TOKEN_KEY } from "../store/types";

export const getToken = (refresh = false) => {
  return Cache.get(ID_TOKEN_KEY, refresh);
};

export const saveToken = (token, expires_in = 55) => {
  Cache.add(ID_TOKEN_KEY, token, expires_in);
};

export const destroyToken = () => {
  Cache.destroy(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
