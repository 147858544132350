import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";
import Cache from "./common/cache";
import IdleVue from "idle-vue";
import Clipboard from 'v-clipboard';
const eventsHub = new Vue();
let idleTime = (hours = 1)=> {
  let microSeconds = 1000;
  let oneMinuteSeconds = 60;
  let oneHourMinute = 60;
  return (microSeconds * oneMinuteSeconds) * (oneHourMinute * hours);
};
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  // idleTime: 6000,
  idleTime: idleTime(5)
});
Vue.use(Clipboard);
// import MockService from "./common/mock/mock.service";

import { VERIFY_AUTH, REFRESH_TOKEN } from "./store/auth.module";
import { ID_TOKEN_KEY } from "./store/types";

Vue.config.productionTip = true;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
// import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "./store/htmlclass.module";

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  if ( to.meta.requiresAuth ){
    // console.log(1);
    // Promise.all([ store.dispatch(VERIFY_AUTH, router) ]).then(()=>{
      // 授权码还没过期
      if ( ! Cache.hasExpired(ID_TOKEN_KEY) ){
        // console.log(2, '[授权码还没过期]');
        next();
        // 授权码存在可是已经过期了
      }else if ( Cache.has(ID_TOKEN_KEY) && Cache.hasExpired(ID_TOKEN_KEY) ) {
        // console.log(3, '[刷新授权码]');
        // 刷新授权码
        store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");
        store.dispatch(REFRESH_TOKEN)
        .then(()=> {
          // console.log(4, '[刷新授权码, 重新设定]');
          // 重新设定
          store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
          next();
        })
        .catch(()=> {store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");console.log(5, '[刷新授权码, 刷新失败]'); next({ name: 'login' })});
      }else if ( ! Cache.has(ID_TOKEN_KEY) ){
        // 根本不存在授权码的情况下跳转到登录页面
        // console.log(6, '[根本不存在授权码的情况下跳转到登录页面]');
        next({ name: 'login' });
      }
    // });

  }else if ( to.meta.requiresGuest ){
    // console.log(7, '[不需要登录的页面]');
    // 不需要登录的页面存在授权码时跳转到首页
    if ( ! Cache.hasExpired(ID_TOKEN_KEY) ){
      // console.log(8, '[存在授权码时跳转到首页]');
      next({name: 'dashboard'});
    }else {
      // console.log(9, '[不需要登录的页面，不存在授权码]');
      next();
    }
  }else {
    // console.log(10);
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach(((to, from) => {
  let { admin } = store.state.auth;
  if ( ! admin.hasOwnProperty('id') ){
    store.dispatch(VERIFY_AUTH)
    .then(()=> {
      // 重新设定
      ApiService.setHeader();
    })
  }
}));

new Vue({
  router,
  store,
  i18n,
  // vuetify,
  render: h => h(App)
}).$mount("#app");
