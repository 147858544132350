export default {
  // 全局的
  errors: (state)=> state.errors, // 错误信息

  // [ADVERTS]
  advert: (state)=> state.adverts.advert,
  adverts: (state)=> state.adverts.adverts,
  advertsMeta: (state)=> state.adverts.advertsMeta,
  advertsError: (state)=> state.adverts.advertsError,
  // [USER]
  user: (state)=> state.user.user,
  users: (state)=> state.user.users,
  usersMeta: (state)=> state.user.usersMeta,
  userError: (state)=> state.user.userError,
  // [ENTRUST USER]
  entrustUser: (state)=> state.user.entrustUser,
  entrustUsers: (state)=> state.user.entrustUsers,
  entrustUsersMeta: (state)=> state.user.entrustUsersMeta,
  entrustStatics: (state)=> state.user.entrustStatics,
  // [USER WALLET LOGS]
  userWalletLogs: (state)=> state.user.userWalletLogs,
  userWalletLogsMeta: (state)=> state.user.userWalletLogsMeta,
  // [SETTING]
  setting: (state)=> state.setting.setting, //
  settings: (state)=> state.setting.settings, //
  settingError: (state)=> state.setting.settingError, //
  // CATEGORY
  category: state=> state.category.category,
  categories: state=> state.category.categories,
  categoriesForAll: state=> state.category.categoriesForAll,
  categoriesMeta: state=> state.category.categoriesMeta,
  categoryError: state=> state.category.categoryError,
  // COLLECTION
  collection: state=> state.collection.collection,
  collections: state=> state.collection.collections,
  collectionsForAll: state=> state.collection.collectionsForAll,
  collectionsMeta: state=> state.collection.collectionsMeta,
  collectionError: state=> state.collection.collectionsError,
  // AUTHOR
  author: state=> state.author.author,
  authors: state=> state.author.authors,
  authorsForAll: state=> state.author.authorsForAll,
  authorsMeta: state=> state.author.authorsMeta,
  authorError: state=> state.author.authorError,

  // NAVIGATIONS
  navigation: state=> state.navigation.navigation,
  navigations: state=> state.navigation.navigations,
  navigationsMeta: state=> state.navigation.navigationsMeta,
  navigationError: state=> state.navigation.navigationError,

  // ARTICLES
  article: state=> state.article.article,
  articles: state=> state.article.articles,
  articlesComment: state=> state.article.articlesComment,
  articlesForAll: state=> state.article.articlesForAll,
  articlesMeta: state=> state.article.articlesMeta,
  articlesCommentMeta: state=> state.article.articlesCommentMeta,
  articleError: state=> state.article.articleError,

  // ARTICLE EPISODES
  articleEpisode: state=> state.articleEpisode.articleEpisode,
  articleEpisodes: state=> state.articleEpisode.articleEpisodes,
  articleEpisodesMeta: state=> state.articleEpisode.articleEpisodesMeta,
  articleEpisodeError: state=> state.articleEpisode.articleEpisodeError,

  // MEMBERSHIP
  membership: state=> state.membership.membership,
  membershipList: state=> state.membership.membershipList,
  membershipListMeta: state=> state.membership.membershipListMeta,
  membershipError: state=> state.membership.membershipError,

  // USER WALLET CASH REQUEST
  cashRequest: state=> state.walletCashRequest.cashRequest,
  cashRequestList: state=> state.walletCashRequest.cashRequestList,
  cashRequestMeta: state=> state.walletCashRequest.cashRequestMeta,
  cashRequestError: state=> state.walletCashRequest.cashRequestError,

  // ORDER
  orders: state=> state.order.orders,
  ordersMeta: state=> state.order.ordersMeta,
  ordersError: state=> state.order.ordersError,

  // CUSTOMER MESSAGES
  customerMessages: state=> state.customer.customerMessages,
  customerMessagesMeta: state=> state.customer.customerMessagesMeta,
  customerMessagesPage: state=> state.customer.customerMessagesPage,
  customerError: state=> state.customer.customerError,

}
