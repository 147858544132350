import ApiService from "@/common/api.service";

export const SET_SETTING_ERROR = 'setSettingError';

import {
  SET_SETTING,
  SET_SETTING_LIST,
  GET_SETTING_LIST,
  DELETE_SETTING,
  UPDATE_SETTING,
  UPDATE_SETTING_LIST
} from "../types";
import handleReject from "../../common/handleReject";

const state = {
  setting: {}, // 当前的配置
  settings: [], // 配置项列表
  settingError: {}, // 配置项错误数据
};

const mutations = {
  [SET_SETTING](state, setting){
    state.setting = setting;
  },
  [SET_SETTING_LIST](state, list){
    state.settings = list;
  },
  [SET_SETTING_ERROR](state, error){
    state.settingError = error;
  }
};

const actions = {
  [GET_SETTING_LIST](context){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/setting')
        .then(response =>{
          context.commit(SET_SETTING_LIST, response.data);
          context.commit(SET_SETTING_ERROR, null);
          resolve(response)
        })
        .catch((response) =>{
          // context.commit(SET_SETTING_ERROR, response.data.errors || response.data.message || response.data || response);
          reject(response);
          handleReject(context, response, SET_SETTING_ERROR);
        })
    })
  },
  [UPDATE_SETTING](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.update(`/manager/setting/`, formData.id, formData)
      .then(response =>{
        context.commit(SET_SETTING, response.data);
        context.commit(SET_SETTING_ERROR, null);
        resolve(response)
      })
      .catch(({response}) =>{
        // context.commit(SET_SETTING_ERROR, response.data.errors || response.data.message || response.data || response);
        reject(response);
        handleReject(context, response, SET_SETTING_ERROR);
      })
    })
  },
  [UPDATE_SETTING_LIST](context, formData){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(`/manager/setting/update-all`, formData)
      .then(response =>{
        // context.commit(SET_SETTING_LIST, response.data);
        context.commit(SET_SETTING_ERROR, null);
        resolve(response)
      })
      .catch(({response}) =>{
        // context.commit(SET_SETTING_ERROR, response.data.errors || response.data.message || response.data || response);
        reject(response);
        handleReject(context, response, SET_SETTING_ERROR);
      })
    })
  },
  [DELETE_SETTING](context, id){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete(`/manager/setting/${id}`)
      .then(response =>{
        context.commit(SET_SETTING_ERROR, null);
        resolve(response)
      })
      .catch(({response}) =>{
        // context.commit(SET_SETTING_ERROR, response.data.errors || response.data.message || response.data || response);
        reject(response);
        handleReject(context, response, SET_SETTING_ERROR);
      })
    })
  }
};

export default {
  state,
  mutations,
  actions
}
