import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "/adverts",
          name: "adverts",
          component: ()=> import('@/views/pages/adverts'),
          children: [
            {
              path: "list",
              name: "adverts.list",
              component: ()=> import("@/views/pages/adverts/List"),
              meta: { requiresAuth: true }
            },
            {
              path: "create",
              name: "adverts.create",
              component: ()=> import("@/views/pages/adverts/Create"),
              meta: { requiresAuth: true }
            },
            {
              path: "edit/:id",
              name: "adverts.edit",
              component: ()=> import("@/views/pages/adverts/Edit"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          path: "/navigations",
          name: "navigations",
          component: ()=> import('@/views/pages/navigation'),
          children: [
            {
              path: "list",
              name: "navigations.list",
              component: ()=> import("@/views/pages/navigation/List"),
              meta: { requiresAuth: true }
            },
            {
              path: "create",
              name: "navigations.create",
              component: ()=> import("@/views/pages/navigation/Create"),
              meta: { requiresAuth: true }
            },
            {
              path: "edit/:id",
              name: "navigations.edit",
              component: ()=> import("@/views/pages/navigation/Edit"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          path: "/categories",
          name: "categories",
          component: ()=> import('@/views/pages/category'),
          children: [
            {
              path: "list",
              name: "categories.list",
              component: ()=> import("@/views/pages/category/List"),
              meta: { requiresAuth: true }
            },
            {
              path: "create",
              name: "categories.create",
              component: ()=> import("@/views/pages/category/Create"),
              meta: { requiresAuth: true }
            },
            {
              path: "edit/:id",
              name: "categories.edit",
              component: ()=> import("@/views/pages/category/Edit"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          path: "/collection",
          name: "collection",
          component: ()=> import('@/views/pages/collection'),
          children: [
            {
              path: "list",
              name: "collection.list",
              component: ()=> import("@/views/pages/collection/List"),
              meta: { requiresAuth: true }
            },
            {
              path: "create",
              name: "collection.create",
              component: ()=> import("@/views/pages/collection/Create"),
              meta: { requiresAuth: true }
            },
            {
              path: "edit/:id",
              name: "collection.edit",
              component: ()=> import("@/views/pages/collection/Edit"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          path: "/authors",
          name: "authors",
          component: ()=> import('@/views/pages/author'),
          children: [
            {
              path: "list",
              name: "authors.list",
              component: ()=> import("@/views/pages/author/List"),
              meta: { requiresAuth: true }
            },
            {
              path: "create",
              name: "authors.create",
              component: ()=> import("@/views/pages/author/Create"),
              meta: { requiresAuth: true }
            },
            {
              path: "edit/:id",
              name: "authors.edit",
              component: ()=> import("@/views/pages/author/Edit"),
              meta: { requiresAuth: true }
            },
            {
              path: "poster",
              name: "authors.poster",
              component: ()=> import("@/views/pages/author/Poster"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          path: "/articles",
          name: "articles",
          component: ()=> import('@/views/pages/article'),
          children: [
            {
              path: "list",
              name: "articles.list",
              component: ()=> import("@/views/pages/article/List"),
              meta: { requiresAuth: true }
            },
            {
              path: "create",
              name: "articles.create",
              component: ()=> import("@/views/pages/article/Create"),
              meta: { requiresAuth: true }
            },
            {
              path: "edit/:id",
              name: "articles.edit",
              component: ()=> import("@/views/pages/article/Edit"),
              meta: { requiresAuth: true }
            },
            {
              path: ":id/details",
              name: "articles.details",
              component: ()=> import("@/views/pages/article/Details"),
              meta: { requiresAuth: true }
            },
            {
              path: "poster",
              name: "articles.poster",
              component: ()=> import("@/views/pages/article/Poster"),
              meta: { requiresAuth: true }
            },
            {
              path: "comments",
              name: "articles.comments",
              component: ()=> import("@/views/pages/article/Comment"),
              meta: { requiresAuth: true }
            },
            {
              path: ":aid/episodes/list",
              name: "articles.episodes.list",
              component: ()=> import("@/views/pages/article/episode/List"),
              meta: { requiresAuth: true }
            },
            {
              path: ":aid/episodes/create",
              name: "articles.episodes.create",
              component: ()=> import("@/views/pages/article/episode/Create"),
              meta: { requiresAuth: true }
            },
            {
              path: ":aid/episodes/edit/:id",
              name: "articles.episodes.edit",
              component: ()=> import("@/views/pages/article/episode/Edit"),
              meta: { requiresAuth: true }
            },
            {
              path: ":aid/downloads",
              name: "articles.download.home",
              component: ()=> import("@/views/pages/article/download/home"),
              meta: { requiresAuth: true }
            },
            {
              path: ":aid/downloads/create",
              name: "articles.download.create",
              component: ()=> import("@/views/pages/article/download/create"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          name: "profile",
          path: "/profile",
          redirect: "/profile/edit",
          component: ()=> import("@/views/pages/profile"),
          children: [
            {
              name: "edit.profile",
              path: "edit",
              component: ()=> import("@/views/pages/profile/Edit"),
              meta: { requiresAuth: true }
            },
            {
              name: "change.password",
              path: "change/password",
              component: ()=> import("@/views/pages/profile/ChangePassword"),
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          path: "/user",
          name: "user",
          component: () => import("@/views/pages/user"),
          children: [
            {
              path: "list",
              name: "user.list",
              component: () => import("@/views/pages/user/List"),
              meta: { requiresAuth: true }
            },
            {
              path: ":id/details",
              name: "user.details",
              component: () => import("@/views/pages/user/Details"),
              meta: { requiresAuth: true }
            },
            {
              path: ":id/vip",
              name: "user.vip",
              component: () => import("@/views/pages/user/Vip"),
              meta: { requiresAuth: true }
            },
            {
              path: "wallet/cash-request",
              name: "user.wallet.cash-request",
              component: () => import("@/views/pages/user/Wallet/CashRequest"),
              meta: { requiresAuth: true }
            },
            {
              path: "entrust/list",
              name: "user.entrust.list",
              component: () => import("@/views/pages/user/Entrust/List"),
              meta: { requiresAuth: true }
            },
            {
              path: "we_chat/customer/history",
              name: "user.we_chat.customer.history",
              component: () => import("@/views/pages/user/WeChat/Customer/History"),
              meta: { requiresAuth: true }
            },
            {
              path: "we_chat/customer/message",
              name: "user.we_chat.customer.message",
              component: () => import("@/views/pages/user/WeChat/Customer/Message"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          path: "/membership",
          name: "membership",
          component: () => import("@/views/pages/membership"),
          children: [
            {
              path: "list",
              name: "membership.list",
              component: () => import("@/views/pages/membership/List"),
              meta: { requiresAuth: true }
            },
            {
              path: "create",
              name: "membership.create",
              component: () => import("@/views/pages/membership/Create"),
              meta: { requiresAuth: true }
            },
            {
              path: "edit/:id",
              name: "membership.edit",
              component: () => import("@/views/pages/membership/Edit"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          path: "/setting",
          name: "setting",
          component: () => import("@/views/pages/setting/Setting"),
          children: [
            {
              path: "create",
              name: "setting.create",
              component: () => import("@/views/pages/setting/Create"),
              meta: { requiresAuth: true }
            },
            {
              path: "list",
              name: "setting.list",
              component: () => import("@/views/pages/setting/List"),
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          path: "/orders",
          name: "orders",
          component: () => import("@/views/pages/order"),
          children: [
            {
              path: "list",
              name: "orders.list",
              component: () => import("@/views/pages/order/List"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          path: "/protocol",
          component: () => import("@/views/pages/protocol"),
          children: [
            {
              path: "/",
              name: "protocol",
              component: () => import("@/views/pages/protocol/Content"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          name: "tools",
          path: "/tools",
          component: () => import(/* webpackChunkName: "wrapper" */ "@/views/pages/wrapper"),
          meta: { requiresAuth: true },
          children: [
            {
              path: "wechat-info",
              name: "wechatInfo",
              component: () => import("@/views/pages/tools/WeChatInfo"),
              meta: { requiresAuth: true }
            },
          ]
        },
        {
          name: "subscribe_message",
          path: "/subscribe_messages",
          component: () => import(/* webpackChunkName: "wrapper" */ "@/views/pages/wrapper"),
          meta: { requiresAuth: true },
          redirect: 'home',
          children: [
            {
              name: 'subscribe_message.home',
              path: 'home',
              meta: { requiresAuth: true, title: 'مۇشتەرى ئۇچۇرى باشقۇرۇش' },
              component: () => import(/* webpackChunkName: "subscribe_message" */ "@/views/pages/subscribeMessage/home"),
            },
            {
              name: 'subscribe_message.history',
              path: ':id/history',
              meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش خاتىرىسى' },
              component: () => import(/* webpackChunkName: "subscribe_message" */ "@/views/pages/subscribeMessage/history"),
            },
            {
              name: 'subscribe_message.send',
              path: ':id/send',
              meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش' },
              component: () => import(/* webpackChunkName: "subscribe_message" */ "@/views/pages/subscribeMessage/send"),
            },
          ]
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login"),
          meta: { requiresGuest: true }
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue")
    }
  ]
});
