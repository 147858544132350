import ApiService from "@/common/api.service";
import handleReject from "../../common/handleReject";
import {
  SET_WALLET_CASH_REQUEST_LIST,
  SET_WALLET_CASH_REQUEST,
  GET_WALLET_CASH_REQUEST_LIST,
} from "../types";

export const SET_WALLET_CASH_REQUEST_ERROR = 'setWalletCashRequestError';

const state = {
  cashRequest: {}, // 钱包数据
  cashRequestList: [], // 钱包列表
  cashRequestMeta: {}, // 钱包列表分页相关数据
  cashRequestError: {}, // 钱包数据发生的错误
};

const mutations = {
  [SET_WALLET_CASH_REQUEST](state, wallet){
    state.cashRequest = wallet;
  },
  [SET_WALLET_CASH_REQUEST_LIST](state, payload){
    let { data: cashRequestList, meta } = payload;
    state.cashRequestList = cashRequestList;
    state.cashRequestMeta = meta;
  },
  [SET_WALLET_CASH_REQUEST_ERROR](state, errors){
    state.cashRequestError = errors;
  },
}

const actions = {
  [GET_WALLET_CASH_REQUEST_LIST](context, { page = 1, filter = 0 }){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/user/wallet/cash-request', `?filter=${filter || 0}&page=${page || 1}`)
      .then(response=>{
        context.commit(SET_WALLET_CASH_REQUEST_LIST, response.data);
        context.commit(SET_WALLET_CASH_REQUEST_ERROR, null);
        resolve(response);
      })
      .catch(response=>{
        reject(response);
        handleReject(context, response, SET_WALLET_CASH_REQUEST_ERROR);
      })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
