import ApiService from "@/common/api.service";

import {
  GET_ORDER_LIST,
  SET_ORDER_LIST,
  SET_ORDERS_ERROR
} from "../types";
import handleReject from "../../common/handleReject";

const state = {
  order: {},
  orders: [],
  ordersMeta: {},
  ordersError: {}
};

const mutations = {
  [SET_ORDER_LIST](state, payload){
    state.orders = payload.data;
    state.ordersMeta = payload.meta;
  },
  [SET_ORDERS_ERROR](state, payload){
    state.ordersError = payload;
  }
};

const actions = {
  [GET_ORDER_LIST](context, {page = 1, start_time = '', end_time = ''}){
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get('/manager/order/list', `?page=${page}&start_time=${start_time || ''}&end_time=${end_time || ''}`)
      .then(response=>{
        context.commit(SET_ORDER_LIST, response.data);
        context.commit(SET_ORDERS_ERROR, null);
        resolve(response);
      })
      .catch(response => {
        reject(response);
        handleReject(context, response, SET_ORDERS_ERROR);
      });
    })
  },
};

export default {
  state,
  mutations,
  actions
}
